import * as React from "react"

import { CheckIcon } from "@heroicons/react/outline"

interface Props {
  title: string
  body: string
  items: { id: string; title: string; body: string }[]
}

const FeatureList = ({ items, title, body }: Props) => (
  <>
    <div className="max-w-3xl mx-auto text-center">
      <h2 className="text-3xl font-extrabold text-gray-900">{title}</h2>
      <p className="mt-4 text-lg text-gray-500">{body}</p>
    </div>
    <dl className="mt-12 justify-center space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
      {items.map(feature => (
        <div key={feature.id} className="relative">
          <dt>
            <CheckIcon
              className="absolute h-6 w-6 text-green-500"
              aria-hidden="true"
            />
            <h3 className="ml-9 text-lg leading-6 font-medium text-gray-900">
              {feature.title}
            </h3>
          </dt>
          <dd className="mt-2 ml-9 text-base text-gray-500">{feature.body}</dd>
        </div>
      ))}
    </dl>
  </>
)

export default FeatureList
