import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { EmojiHappyIcon, CheckCircleIcon } from "@heroicons/react/outline"
import Quote from "../components/quote"
import ProjectCta from "../components/projectCta"
import ServiceList from "../components/serviceList"
import SimpleHeader from "../components/simpleHeader"
import FeatureList from "../components/featureList"
import { graphql } from "gatsby"
import ReviewOverview from "../components/reviewOverview"

const IndexPage = ({
  data: {
    contentfulServices: {
      title,
      introduction,
      contactUsCta,
      workWithUsTitle,
      workWithUsBody,
      workWithUsBenefits,
      project: {
        slug,
        featuredImage,
        customerName,
        address,
        testimonial: { testimonial },
      },
    },
  },
}) => (
  <Layout>
    <Seo title="Services" />

    <SimpleHeader
      smallTitle="Our Services"
      title={title}
      description={introduction}
    />

    <ServiceList title="Our services" />

    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <FeatureList
          items={workWithUsBenefits}
          title={workWithUsTitle}
          body={workWithUsBody}
        />
      </div>
    </div>

    <ReviewOverview />

    <Quote
      projectLink={`/projects/${slug}`}
      testimonial={{
        text: testimonial,
        address,
        customerName,
        image: featuredImage.file.url,
        imageAlt: featuredImage.description,
      }}
    />

    <ProjectCta text={contactUsCta} />
  </Layout>
)

export const query = graphql`
  {
    contentfulServices {
      id
      title
      introduction
      contactUsCta

      workWithUsTitle
      workWithUsBody
      workWithUsBenefits {
        id
        title
        body
      }

      project {
        title
        customerName
        address
        slug

        featuredImage {
          description
          file {
            url
          }
        }

        testimonial {
          testimonial
        }
      }
    }
  }
`

export default IndexPage
